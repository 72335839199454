import React from 'react';
//
import useObjectOfFongHe from '../../hooks/useObjectOfFongHe';
import Dashboard from '../../components/Dashboard';

const FongHe = () => {
  const { data: objectList } = useObjectOfFongHe();

  if (!objectList || objectList.length === 0) {
    return <></>;
  }

  return (
    <Dashboard
      objectList={objectList}
      metricsTypeList={['temperature', 'irr', 'co2', 'plantTree']}
      generationType={'accumulatedKwh'}
    />
  );
};

export default FongHe;
