import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Row, Col, Layout } from 'antd';
import axios from 'axios';
import moment from 'moment';
//
import RfdmeChart from '../../components/Chart';
//
import MiaoliPic from '../../images/mylandingpage/map/img_t8168_monitor.jpg';
import MiaoliLogoSolar from '../../images/mylandingpage/bg_miaoli_display_banner.svg';
import MiaoliLogoLoad from '../../images/mylandingpage/logo_miaoli_display_banner.svg';
//
import TempIcon from '../../images/mylandingpage/temp.svg';
import SunIcon from '../../images/mylandingpage/sun.svg';
import Co2Icon from '../../images/mylandingpage/co2.svg';
import TreeIcon from '../../images/mylandingpage/tree.svg';
//
import appConfigs from '../../configs';
import useObjectOfMiaoli from '../../hooks/useObjectOfMiaoli';
import { IsValidApiRsps } from '../../utils/valid';
import { NumberFormat } from '../../utils/format';

const { Header, Content, Footer } = Layout;
//
const REFRESH_TIME = parseInt(appConfigs.refreshMinutes) * 60000;
const SWITCH_TIME = parseInt(appConfigs.switchMinutes) * 60000;

const Miaoli = () => {
  const [initial, setInitial] = useState(true);
  const [metrics, setMetrics] = useState({}); //指標數據
  const [generation, setGeneration] = useState({}); //發電數據
  const [consumption, setConsumption] = useState({}); //用電數據
  const [mode, setMode] = useState('generation');
  const [time, setTime] = useState(moment().format('YYYY-MM-DD HH:mm'));
  const { data: objectList } = useObjectOfMiaoli();
  //
  const solarObject = useMemo(() => {
    if (!Array.isArray(objectList)) return {};
    const item = objectList.find((f) => f.system === 'SolarPlant');
    if (!item) return {};
    return { type: 'kw', objectID: item.value };
  }, [objectList]);
  const loadObject = useMemo(() => {
    if (!Array.isArray(objectList)) return {};
    const item = objectList.find((f) => f.system === 'Load');
    if (!item) return {};
    return { objectID: item.value };
  }, [objectList]);

  // 更新時間
  useEffect(() => {
    const intervalTime = setInterval(() => {
      const now = moment();
      const nowString = moment().format('YYYY-MM-DD HH:mm');
      if (now.seconds() === 0 || time !== nowString) {
        setTime(nowString);
      }
    }, 1000);

    return () => {
      clearInterval(intervalTime);
    };
  }, [time]);

  const fetchMetrics = useCallback(() => {
    const postData = {
      typeList: ['temperature', 'irr', 'co2', 'plantTree'],
      objectID: solarObject.objectID,
      timezone: '8',
    };
    return axios.post(`/api/object/metrics`, postData);
  }, [solarObject]);

  const fetchGenerationOrConsumptionData = useCallback(
    (queryMode) => {
      const postData =
        queryMode === 'consumption' ? { ...loadObject, timezone: '8' } : { ...solarObject, timezone: '8' };
      return axios.post(`/api/object/${queryMode}`, postData);
    },
    [solarObject, loadObject]
  );

  const fetchData = useCallback(
    async (mode) => {
      console.log(mode);
      if (!solarObject.objectID || !loadObject.objectID) return;

      const metricsPromise = fetchMetrics();
      const generationOrConsumptionPromise = fetchGenerationOrConsumptionData(mode);
      await axios
        .all([metricsPromise, generationOrConsumptionPromise])
        .then(
          axios.spread((metricsResponse, dataResponse) => {
            if (IsValidApiRsps(metricsResponse) && IsValidApiRsps(dataResponse)) {
              setMetrics(metricsResponse.data.data);
              if (mode === 'consumption') {
                setConsumption(dataResponse.data.data);
              } else {
                setGeneration(dataResponse.data.data);
              }
              return true;
            }
          })
        )
        .catch((err) => {
          console.error('API call failed:', err);
        });
      return false;
    },
    [solarObject, loadObject, fetchMetrics, fetchGenerationOrConsumptionData]
  );

  // 第一次載入
  useEffect(() => {
    if (!initial || !solarObject.objectID || !loadObject.objectID) return;

    if (fetchData(mode)) {
      setInitial(false);
    }
  }, [initial, mode, solarObject, loadObject, fetchData]);

  // 更新指標及數據
  useEffect(() => {
    // 取得 [目前模式] 的指標及數據，若成功則更新資料
    const intervalUpdate = setInterval(() => {
      fetchData(mode);
    }, REFRESH_TIME);

    // 取得 [下一模式] 的指標及數據，若成功則更新資料及變換模式
    const intervalMode = setInterval(() => {
      const nextMode = mode === 'consumption' ? 'generation' : 'consumption';
      if (fetchData(nextMode)) {
        setMode(nextMode);
      }
    }, SWITCH_TIME);

    return () => {
      clearInterval(intervalUpdate);
      clearInterval(intervalMode);
    };
  }, [mode, fetchData]);

  const convertTime = (timeStr) => {
    if (!timeStr) return '';
    // 使用冒號分割時間
    let [hours, minutes] = timeStr.split(':');
    // 轉換小時為整數型別
    hours = parseInt(hours);
    // 判斷是否為下午
    const isPM = hours >= 12;
    // 如果是下午，則小時數減去12 (除非是12點，因為12:00 PM是正確的)
    if (isPM && hours > 12) {
      hours -= 12;
    }
    // 將小時轉換回為雙位數的字串
    const hoursStr = hours.toString().padStart(2, '0');
    // 決定是AM還是PM
    const amPm = isPM ? 'PM' : 'AM';
    // 返回結果
    return `${hoursStr}:${minutes} ${amPm}`;
  };

  return (
    <>
      <Header className="app-page-header">
        <Row>
          <Col className="app-page-pic">
            <img src={MiaoliPic} alt="rfdme-pic" />
          </Col>
          <Col className="app-page-logo">
            <img src={mode === 'generation' ? MiaoliLogoSolar : MiaoliLogoLoad} alt="rfdme-logo" />
          </Col>
        </Row>
      </Header>
      <Content className="app-page-content">
        <div className="app-page-card-wrap">
          <Row gutter={24} style={{ width: '100%' }}>
            <Col span={6}>
              <div className="app-page-card">
                <div className="cover">
                  <img src={TempIcon} alt="rfdme-temp" />
                  <span className="num">{NumberFormat(metrics.temperature, 1)}</span>
                  <span className="unit">°C</span>
                </div>
                <p>{time}</p>
              </div>
            </Col>
            <Col span={6}>
              <div className="app-page-card">
                <div className="cover">
                  <img src={SunIcon} alt="rfdme-sun" />
                  <span className="num">{NumberFormat(metrics.irr, 1)}</span>
                  <span className="unit">W/M²</span>
                </div>
                <p>即時日照</p>
              </div>
            </Col>
            <Col span={6}>
              <div className="app-page-card">
                <div className="cover">
                  <img src={Co2Icon} alt="rfdme-co2" />
                  <span className="num">{NumberFormat(metrics.co2, 1)}</span>
                  <span className="unit">公噸</span>
                </div>
                <p>CO2減排量</p>
              </div>
            </Col>
            <Col span={6}>
              <div className="app-page-card">
                <div className="cover">
                  <img src={TreeIcon} alt="rfdme-tree" />
                  <span className="num">{NumberFormat(metrics.plantTree, 1)}</span>
                  <span className="unit">千棵</span>
                </div>
                <p>約當造林數</p>
              </div>
            </Col>
          </Row>
        </div>
        {mode === 'generation' ? (
          <>
            <div className="app-page-data-wrap">
              <Row gutter={[16, 16]} style={{ width: '100%' }}>
                <Col span={8}>
                  <div className="app-page-data">
                    <div className="title">總容量</div>
                    <span className="num">{NumberFormat(generation.capacity, 2)}</span>
                    <span className="unit">KWP</span>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="app-page-data">
                    <div className="title">即時發電功率</div>
                    <span className="num">
                      <span className="focus">{NumberFormat(generation.nowPower, 2)}</span>
                    </span>
                    <span className="unit">KW</span>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="app-page-data">
                    <div className="title">等效日照小時</div>
                    <span className="num">{NumberFormat(generation.sunshine, 2)}</span>
                    <span className="unit">HR</span>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="app-page-data">
                    <div className="title">總累積發電量</div>
                    <span className="num">{NumberFormat(generation.totalPower, 2)}</span>
                    <span className="unit">KWH</span>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="app-page-data">
                    <div className="title">今日高峰發電</div>
                    <div className="triggertime">發生時間 {convertTime(generation.todayMaxPowerTime)}</div>
                    <span className="num">
                      <span className="focus">{NumberFormat(generation.todayMaxPower, 2)}</span>
                    </span>
                    <span className="unit">KW</span>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="app-page-data">
                    <div className="title">今日累積發電量</div>
                    <span className="num">{NumberFormat(generation.todaySumPower, 2)}</span>
                    <span className="unit">KWH</span>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="app-page-chart-wrap">
              <div className="app-page-chart">
                <RfdmeChart
                  labels={{ x: 'HR', y: 'KW' }}
                  title="及時發電功率"
                  name="發電功率"
                  data={generation.chartData}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="app-page-data-wrap">
              <Row gutter={[16, 16]} style={{ width: '100%' }}>
                <Col span={8}>
                  <div className="app-page-data">
                    <div className="title">伏特</div>
                    <span className="num">{NumberFormat(consumption.volt, 2)}</span>
                    <span className="unit">V</span>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="app-page-data">
                    <div className="title">安培</div>
                    <span className="num">{NumberFormat(consumption.ampere, 2)}</span>
                    <span className="unit">A</span>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="app-page-data">
                    <div className="title">用電瓦數</div>
                    <span className="num">{NumberFormat(consumption.activeWattage, 2)}</span>
                    <span className="unit">KW</span>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="app-page-data">
                    <div className="title">用電度數</div>
                    <span className="num">{NumberFormat(consumption.activePower, 2)}</span>
                    <span className="unit">KWH</span>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="app-page-data">
                    <div className="title">頻率</div>
                    <span className="num">{NumberFormat(consumption.frequency, 2)}</span>
                    <span className="unit">Hz</span>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="app-page-data">
                    <div className="title">功率因數</div>
                    <span className="num">{NumberFormat(consumption.powerFactor, 2)}</span>
                    <span className="unit"></span>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="app-page-chart-wrap">
              <div className="app-page-chart">
                <RfdmeChart
                  labels={{ x: 'HR', y: 'KW' }}
                  title="及時用電瓦數"
                  name="用電瓦數"
                  data={consumption.chartData}
                  chartType="load"
                />
              </div>
            </div>
          </>
        )}
      </Content>
      <Footer className="app-page-footer">
        <p>東迪能源股份有限公司</p>
      </Footer>
    </>
  );
};

export default Miaoli;
