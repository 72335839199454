import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Layout } from 'antd';
import axios from 'axios';
import moment from 'moment';
//
import RfdmeSelect from '../../components/Select';
import RfdmeChart from '../../components/Chart';
//
import RfdmeLogo from '../../images/mylandingpage/logo_rfdme.svg';
import RfdmePic from '../../images/mylandingpage/view.png';
import TempIcon from '../../images/mylandingpage/temp.svg';
import SunIcon from '../../images/mylandingpage/sun.svg';
import Co2Icon from '../../images/mylandingpage/co2.svg';
import TreeIcon from '../../images/mylandingpage/tree.svg';
//
import appConfigs from '../../configs';
import useObjectOfULong from '../../hooks/useObjectOfULong';
import { IsValidApiRsps } from '../../utils/valid';
import { NumberFormat } from '../../utils/format';
const { Header, Content, Footer } = Layout;
//
const REFRESH_TIME = parseInt(appConfigs.refreshMinutes) * 60000;
const SWITCH_TIME = 60000; //友良是每分鐘轉換1次

const ULong = () => {
  const [initial, setInitial] = useState(true);
  const [objectData, setObjectData] = useState(null);
  const [metrics, setMetrics] = useState({}); //指標數據
  const [generation, setGeneration] = useState({}); //發電數據
  const [time, setTime] = useState(moment().format('YYYY-MM-DD HH:mm'));
  const { data: objectList } = useObjectOfULong();
  const lastApiCallTimeRef = useRef({}); //紀錄每個案場的最後一次資料更新時間

  // 更新時間
  useEffect(() => {
    const intervalTime = setInterval(() => {
      const now = moment();
      const nowString = moment().format('YYYY-MM-DD HH:mm');
      if (now.seconds() === 0 || time !== nowString) {
        setTime(nowString);
      }
    }, 1000);

    return () => {
      clearInterval(intervalTime);
    };
  }, [time]);

  const fetchMetrics = useCallback((objectData) => {
    const postData = {
      typeList: ['temperature', 'irr', 'co2', 'plantTree'],
      objectID: objectData.value,
      timezone: '8',
    };

    return axios.post(`/api/object/metrics`, postData);
  }, []);

  const fetchGenerationData = useCallback((objectData) => {
    const postData = { type: 'kwh', objectID: objectData.value, timezone: '8' };

    return axios.post(`/api/object/generation`, postData);
  }, []);

  const fetchData = useCallback(
    async (objectData) => {
      if (!objectData) return;

      const metricsPromise = fetchMetrics(objectData);
      const generationPromise = fetchGenerationData(objectData);
      await axios
        .all([metricsPromise, generationPromise])
        .then(
          axios.spread((metricsResponse, generationResponse) => {
            if (IsValidApiRsps(metricsResponse) && IsValidApiRsps(generationResponse)) {
              setMetrics((prev) => ({ ...prev, [objectData.value]: metricsResponse.data.data }));
              setGeneration((prev) => ({ ...prev, [objectData.value]: generationResponse.data.data }));
              return true;
            }
          })
        )
        .catch((err) => {
          console.error('API call failed:', err);
        });
      return false;
    },
    [fetchMetrics, fetchGenerationData]
  );

  // 第一次載入
  useEffect(() => {
    if (!initial || !objectList || objectList.length === 0) return;

    const object = objectList[0];
    if (fetchData(object)) {
      setInitial(false);
      setObjectData(object);
    }
  }, [initial, objectList, fetchData]);

  // 更新指標及數據
  useEffect(() => {
    const intervalObject = setInterval(() => {
      if (objectData && objectList && objectList.length > 0) {
        const index = objectList.findIndex((item) => item.key === objectData.key);
        const newIndex = (index + 1) % objectList.length;
        const nextObject = objectList[newIndex];
        if (
          lastApiCallTimeRef.current[nextObject.value] === undefined ||
          Date.now() - lastApiCallTimeRef.current[nextObject.value] >= REFRESH_TIME
        ) {
          if (fetchData(nextObject)) {
            lastApiCallTimeRef.current[nextObject.value] = Date.now();
            setObjectData(nextObject);
          }
        }
      }
    }, SWITCH_TIME);

    return () => {
      clearInterval(intervalObject);
    };
  }, [objectData, objectList, fetchData]);

  return (
    <>
      <Header className="app-page-header">
        <Row>
          <Col className="app-page-pic">
            <img src={RfdmePic} alt="rfdme-pic" />
          </Col>
          <Col className="app-page-logo">
            <img src={RfdmeLogo} alt="rfdme-logo" className="rfdme-logo" />
            <div className="app-page-select-wrap">
              <RfdmeSelect options={[{ ...objectData }]} value={objectData && objectData.value} />
            </div>
          </Col>
        </Row>
      </Header>
      <Content className="app-page-content">
        <div className="app-page-card-wrap">
          <Row gutter={24} style={{ width: '100%' }}>
            <Col span={6}>
              <div className="app-page-card">
                <div className="cover">
                  <img src={TempIcon} alt="rfdme-temp" />
                  <span className="num">{NumberFormat(metrics[objectData?.value]?.temperature, 1)}</span>
                  <span className="unit">°C</span>
                </div>
                <p>{time}</p>
              </div>
            </Col>
            <Col span={6}>
              <div className="app-page-card">
                <div className="cover">
                  <img src={SunIcon} alt="rfdme-sun" />
                  <span className="num">{NumberFormat(metrics[objectData?.value]?.irr, 1)}</span>
                  <span className="unit">W/M²</span>
                </div>
                <p>即時日照</p>
              </div>
            </Col>
            <Col span={6}>
              <div className="app-page-card">
                <div className="cover">
                  <img src={Co2Icon} alt="rfdme-co2" />
                  <span className="num">{NumberFormat(metrics[objectData?.value]?.co2, 1)}</span>
                  <span className="unit">公噸</span>
                </div>
                <p>CO2減排量</p>
              </div>
            </Col>
            <Col span={6}>
              <div className="app-page-card">
                <div className="cover">
                  <img src={TreeIcon} alt="rfdme-tree" />
                  <span className="num">{NumberFormat(metrics[objectData?.value]?.plantTree, 1)}</span>
                  <span className="unit">千棵</span>
                </div>
                <p>約當造林數</p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="app-page-data-wrap">
          <Row gutter={[16, 16]} style={{ width: '100%' }}>
            <Col span={8}>
              <div className="app-page-data">
                <div className="title">總容量</div>
                <span className="num">{NumberFormat(generation[objectData?.value]?.capacity, 2)}</span>
                <span className="unit">KWP</span>
              </div>
            </Col>
            <Col span={8}>
              <div className="app-page-data">
                <div className="title">月累計發電量</div>
                <span className="num">
                  <span className="focus">{NumberFormat(generation[objectData?.value]?.monthPower, 2)}</span>
                </span>
                <span className="unit">KWH</span>
              </div>
            </Col>
            <Col span={8}>
              <div className="app-page-data">
                <div className="title">等效日照小時</div>
                <span className="num">{NumberFormat(generation[objectData?.value]?.sunshine, 2)}</span>
                <span className="unit">HR</span>
              </div>
            </Col>
            <Col span={8}>
              <div className="app-page-data">
                <div className="title">總累積發電量</div>
                <span className="num">{NumberFormat(generation[objectData?.value]?.totalPower, 2)}</span>
                <span className="unit">KWH</span>
              </div>
            </Col>
            <Col span={8}>
              <div className="app-page-data">
                <div className="title">年累計發電量</div>
                <span className="num">
                  <span className="focus">{NumberFormat(generation[objectData?.value]?.yearPower, 2)}</span>
                </span>
                <span className="unit">KWH</span>
              </div>
            </Col>
            <Col span={8}>
              <div className="app-page-data">
                <div className="title">今日累積發電量</div>
                <span className="num">{NumberFormat(generation[objectData?.value]?.todaySumPower, 2)}</span>
                <span className="unit">KWH</span>
              </div>
            </Col>
          </Row>
        </div>
        <div className="app-page-chart-wrap">
          <div className="app-page-chart">
            <RfdmeChart
              labels={{ x: 'HR', y: 'KWH' }}
              title="今日累積發電量"
              name="累積發電量"
              data={generation[objectData?.value]?.chartData}
            />
          </div>
        </div>
      </Content>
      <Footer className="app-page-footer"></Footer>
    </>
  );
};

export default ULong;
